import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
export default class {
  screens = document.querySelectorAll('.js-quiz-screen');
  info = document.querySelector('.quizwrap__info');
  state = {};
  quests = {};

  constructor() {
    this.listener();
    this.quizDrag();
    this.screens.forEach((screen, index) => {
      const title = screen.querySelector('.quiz__title');
      title ? this.quests["quiz" + (index + 1)] = title.innerHTML.replace('<br>', "") : null;
    });
  }

  listener() {
    document.addEventListener('click', e => {
      const nextButton = e.target.closest('[data-to]');

      if (nextButton) {
        e.preventDefault();
        this.goToQuest(parseInt(nextButton.dataset.to));
      }
    });
    document.addEventListener('change', e => {
      if (e.target.matches('.js-quiz-screen input[type="radio"]')) {
        this.radioActivate(e.target);
      }

      const checkbox3 = e.target.closest('.js-quiz-screen input[type="checkbox"]');

      if (checkbox3) {
        this.state.quiz3x = "";

        if (checkbox3.checked) {
          this.state.quiz3x = " + Цокольный этаж";
        }

        this.logstate();
      }
    });
  }

  radioActivate(radio) {
    const name = radio.name;
    this.state[name] = radio.value;
    const screen = radio.closest('.js-quiz-screen');

    if (screen && screen.querySelector('.button[disabled]')) {
      screen.querySelector('.button[disabled]').removeAttribute('disabled');
    }

    this.logstate();
  }

  goToQuest(numb) {
    this.screens.forEach(screen => {
      screen.classList.toggle('is-active', screen.classList.contains('quiz' + numb));
      setTimeout(() => {
        screen.classList.toggle('is-visual', screen.classList.contains('quiz' + numb));
        const wrap = document.querySelector('.quizwrap');
        window.scrollTo({
          top: wrap.getBoundingClientRect().top + window.scrollY - 30,
          behavior: 'smooth'
        });
      }, 0);
    });
    document.querySelector('.quiz__man').classList.toggle('is-visible', numb > 1 && numb != this.screens.length);

    if (numb > 4) {
      this.dumpState();
    }

    this.info.classList.toggle('is-hidden', numb === this.screens.length);
    this.info.querySelector('i').style.width = numb / this.screens.length * 100 + "%";
    this.info.querySelector('.quizwrap__infotxt').innerHTML = `Вопрос ${numb} из ${this.screens.length - 1}`;
  }

  quizDrag() {
    const drag = document.querySelector('.js-quizdrag');
    const dragInp = document.querySelector('.js-quiz2-input');
    noUiSlider.create(drag, {
      start: 150,
      animate: false,
      range: {
        min: 49,
        max: 400
      },
      step: 1,
      connect: [true, false],
      tooltips: [{
        to: function (value) {
          if (value < 50) return "Mенее 50 м²";
          return parseInt(value) + " м²";
        },
        from: function (value) {
          return parseInt(value);
        }
      }]
    });
    drag.noUiSlider.on('update', (values, handle) => {
      this.state.quiz2 = parseInt(values[handle]) + " м²";
      this.logstate();
    });
  }

  dumpState() {
    const textbox = document.querySelector('[name="quizdata"]');

    if (!textbox) {
      console.log("Ошибка. Поле для вопросов квиза не найдено");
      return;
    }

    let result = "";

    for (const key in this.quests) {
      if (this.quests[key]) {
        result += `${this.quests[key]} : ${this.state[key]} \n`;

        if (key === "quiz3" && this.state.quiz3x) {
          result += `${this.state.quiz3x} \n`;
        }
      }
    }

    textbox.value = result;
  }

  logstate() {
    return;
    console.log(this.state);
  }

}