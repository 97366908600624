import HystModal from 'hystmodal';
import 'hystmodal/dist/hystmodal.min.css';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min';
import tippy, { createSingleton } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import Quiz from './quiz';
import { mapsInit, loadYamaps } from './maps';
import phoneMasks from './phonemasks';
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

if (document.querySelector('.quizwrap')) {
  new Quiz();
}

phoneMasks();
document.addEventListener('wpcf7mailsent', function (event) {
  if (event.detail.contactFormId == "177") {
    ym(46624764, 'reachGoal', 'vent_recall', {}, () => {
      console.log(`Цель 'vent_recall' выполнена`);
    });
  }

  if (event.detail.contactFormId == "124") {
    console.log(event.detail.formData.get('froms'));

    if (event.detail.formData.get('froms').includes('Получите презентацию')) {
      ym(46624764, 'reachGoal', 'vent_form', {}, () => {
        console.log(`Цель 'vent_form' выполнена`);
      });
    }

    if (event.detail.formData.get('froms').includes('Узнать подходит ли')) {
      ym(46624764, 'reachGoal', 'vent_formfree', {}, () => {
        console.log(`Цель 'vent_formfree' выполнена`);
      });
    }

    if (event.detail.formData.get('froms').includes('прайс-лист')) {
      ym(46624764, 'reachGoal', 'vent_formfree', {}, () => {
        console.log(`Цель 'vent_formprice' выполнена`);
      });
    }
  }

  if (event.detail.contactFormId == "126") {
    ym(46624764, 'reachGoal', 'vent_quiz', {}, () => {
      console.log(`Цель 'vent_quiz' выполнена`);
    });
  }

  if (event.detail.contactFormId == "125") {
    ym(46624764, 'reachGoal', 'vent_formcalc', {}, () => {
      console.log(`Цель 'vent_formcalc' выполнена`);
    });
  }

  window.location.href = "/thankyou/";
}, false);
window.mapsInit = mapsInit;
/**
 * Модальные окна
 * см. https://addmorescripts.github.io/hystModal/index_ru.html
 */

window.modalInstance = new HystModal({
  linkAttributeName: "data-hystmodal",
  waitTransitions: true,
  beforeOpen: function (modal) {},
  afterClose: function (modal) {}
});
window.modalMenuInstance = new HystModal({
  linkAttributeName: "data-menumodal",
  waitTransitions: true,
  backscroll: false,
  catchFocus: false
});
/**
 * Скрипт GLightbox запуск на элементах с классом .glightbox
 * см. https://biati-digital.github.io/glightbox/
 */

window.lightbox = GLightbox({
  plyr: {
    config: {
      ratio: '16:9',
      youtube: {
        noCookie: true,
        rel: 0,
        showinfo: 0,
        iv_load_policy: 3
      }
    }
  }
});
tippy('.js-tippy', {
  content: reference => {
    return reference.parentElement.querySelector('.js-tippy-content');
  },
  theme: 'light',
  placement: 'bottom',
  allowHTML: true,
  offset: [0, 15]
});

const accordeon = () => {
  const testEl = document.querySelector('[data-hideopen]');
  if (!testEl) return;
  document.addEventListener('click', e => {
    const accordLink = e.target.closest('[data-hideopen]');
    if (!accordLink) return;
    e.preventDefault();
    const accordWrap = document.querySelector(`[data-hidewrap="${accordLink.dataset.hideopen}"]`);
    const accordBox = document.querySelector(`[data-hidebox="${accordLink.dataset.hideopen}"]`);
    if (!accordBox) return; // console.log(accordBox);

    if (accordWrap.classList.contains('is-open')) {
      accordBox.style.height = `${accordBox.scrollHeight}px`;
      accordWrap.classList.remove('is-open');
      accordLink.classList.remove('is-open');
      setTimeout(() => {
        accordBox.style.height = "";
      }, 0);
      return;
    }

    accordBox.style.height = 0;
    accordWrap.classList.add('is-open');
    accordLink.classList.add('is-open');
    setTimeout(() => {
      accordBox.style.height = `${accordBox.scrollHeight}px`;
    }, 0);
  });
};

accordeon(); //scroll to top button and Yandex Map

(function scrollTop() {
  const scrollButton = document.querySelector('.scrollTop');
  if (!scrollButton) return;
  let trigger = false;
  let isMapNeed = true;

  function resetTrigger() {
    trigger = true;
  }

  window.addEventListener('scroll', resetTrigger);
  setInterval(function () {
    if (!trigger) return;
    trigger = false;
    let scrolled = window.pageYOffset || document.documentElement.scrollTop;

    if (scrolled >= 400) {
      scrollButton.classList.add('active');
    } else {
      scrollButton.classList.remove('active');
    }

    heroBotChanger(scrolled); // Загрузка карты, при прокрутке

    if (isMapNeed && scrolled >= 3000) {
      // console.log("Карта загрузилася");
      loadYamaps();
      isMapNeed = false;
    }
  }, 250);
  scrollButton.addEventListener('click', function (e) {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  });
})();

let isHeroBotFix = false;
const footer = document.querySelector('.footer');

const heroBotChanger = scrolled => {
  const footerCoords = footer.getBoundingClientRect().top + scrolled - 1000;

  if (!isHeroBotFix && (scrolled > 400 || scrolled < footerCoords)) {
    document.body.classList.add('herobot--fixed');
    isHeroBotFix = true;
  }

  if (isHeroBotFix && (scrolled <= 400 || scrolled > footerCoords)) {
    document.body.classList.remove('herobot--fixed');
    isHeroBotFix = false;
  }
};

const loadGallerySwiper = elem => {
  elem.swiperInst = new Swiper(elem, {
    autoHeight: false,
    loop: false,
    spaceBetween: 15,
    slidesPerView: 'auto',
    pagination: {
      el: elem.closest('.casegallery').querySelector('.casegallery__pag'),
      type: "bullets",
      clickable: true
    },
    navigation: {
      prevEl: elem.closest('.casegallery').querySelector('.ctrl__prev'),
      nextEl: elem.closest('.casegallery').querySelector('.ctrl__next')
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 30
      },
      992: {
        slidesPerView: 4,
        spaceBetween: 30
      }
    }
  });
};

const loadNewCaseGalleries = () => {
  document.querySelectorAll('.js-gallery-swiper').forEach(elem => {
    loadGallerySwiper(elem);
  });
};

loadNewCaseGalleries();

const loadCases = () => {
  document.addEventListener('click', async e => {
    const button = e.target.closest('.js-loadproducts-button');
    if (!button) return;
    e.preventDefault();
    const place = document.querySelector('.js-products-place');
    button.classList.add('is-loading');
    place.classList.add('is-loading');
    const ajaxBody = new FormData();
    ajaxBody.append("action", "frontcases");
    const response = await fetch('/wp-admin/admin-ajax.php', {
      method: 'POST',
      credentials: 'include',
      body: ajaxBody
    });

    if (!response.ok) {
      console.log(response);
    }

    const result = await response.text();
    place.insertAdjacentHTML('beforeend', result);
    place.classList.remove('is-loading');
    button.classList.remove('is-loading');
    button.parentElement.remove();
    place.querySelectorAll('.swiper').forEach(selector => {
      loadGallerySwiper(selector);
    });
    window.lightbox.reload(); // console.log(result);
  });
};

loadCases();
document.addEventListener('click', e => {
  const scrollButt = e.target.closest('[data-scrollto]');
  if (!scrollButt) return;
  e.preventDefault();
  const hrefLink = scrollButt.getAttribute('href');
  const needEl = document.querySelector(hrefLink);

  if (!needEl) {
    console.log(`Элемента ${hrefLink} нет`);
    return;
  }

  let offset = 0;

  if (scrollButt.dataset.offset) {
    offset = parseInt(scrollButt.dataset.offset);
  } // console.log(offset);
  // console.log(`Прокрутка к элементу ${hrefLink}`);


  window.scrollTo({
    top: needEl.getBoundingClientRect().top + window.scrollY - offset,
    behavior: scrollButt.dataset.hard ? 'auto' : 'smooth'
  });
});

function timesEnd() {
  const saleBlock = document.querySelector('.saleblock');
  saleBlock && saleBlock.remove();
  const lastBlockTitle = document.querySelector('.lastblock__title b');
  lastBlockTitle && lastBlockTitle.remove();
  const button = document.querySelector('.lastblock .wpcf7-submit span');
  button && (button.innerHTML = "Получить примерный расчет");
  const inputHidden = document.querySelector('.lastblock input[name="froms"]');
  inputHidden && (inputHidden.value = "Получить примерный расчёт (скидка сгорела)");
}

const timeCounter = () => {
  const counter = document.querySelector('.js-counter');
  if (!counter) return;
  const clone = counter.cloneNode(true);
  let time = {
    m: parseInt(clone.querySelector('i').innerHTML),
    s: parseInt(clone.querySelector('span').innerHTML)
  };
  let trigger = true;

  const tick = () => {
    if (time.s === 0) {
      if (time.m === 0) {
        trigger = false;
        counter.innerHTML = `Время вышло`;
        timesEnd();
        return;
      }

      time.m = time.m - 1;
      time.s = 59;
    } else {
      time.s = time.s - 1;
    }

    counter.innerHTML = `${time.m} мин ${time.s} сек`;
    setTimeout(tick, 1000);
  };

  setTimeout(tick, 1000);
};

timeCounter();
/**
 * Замена текста в кнопках формы .js-buttontxt на содержимое тега .js-buttonneed
 * (ищется внутри общего родителя .js-formwrap)
 */

const buttonTxtChanger = () => {
  document.querySelectorAll('.js-buttontxt').forEach(elem => {
    const parent = elem.closest('.js-formwrap');
    if (!parent) return;
    const needElement = parent.querySelector('.js-buttonneed');
    if (!needElement) return;
    elem.innerHTML = needElement.innerHTML;
  });
};

buttonTxtChanger();
document.addEventListener('click', e => {
  const yaGoals = e.target.closest('[data-yagoal]');
  if (!yaGoals) return;
  const targetName = yaGoals.dataset.yagoal;
  ym(46624764, 'reachGoal', targetName, {}, () => {
    console.log(`Цель ${targetName} выполнена`);
  });
});