/**
 * Запуск яндекс карты в контейнере class="yamaps". Должен быть также аттрибут
 * с координатами вида data-coords="[53.506522, 49.416028]"
 * и подсказкой data-hint="улица Карла Маркса, 77"
 */
export function mapsInit() {
  // console.log('Запуск карты');
  let selector = document.querySelector('.js-map-frame');
  let coordsJS = [mapdata[0].lat, mapdata[0].lng];
  let hintText = selector.getAttribute('data-hint');
  let myMap = new ymaps.Map(selector, {
    center: coordsJS,
    controls: ['zoomControl'],
    zoom: 5,
    behaviors: ['drag']
  }, {
    yandexMapDisablePoiInteractivity: true,
    suppressMapOpenBlock: true
  }); // console.log(mapdata);

  const boxTemplate = document.getElementById('js-map-templates');
  const boxVisible = document.querySelector('.js-map-object');
  let iconWidth = 50;
  let iconHeight = 69;

  if (window.innerWidth < 992) {
    iconWidth = 30;
    iconHeight = 41;
  }

  mapdata.forEach(coords => {
    // console.log(coords);
    const mark = new ymaps.Placemark([coords.lat, coords.lng], {
      balloonContent: null,
      iconCaption: null,
      blockId: coords.id
    }, {
      iconLayout: 'default#image',
      iconImageHref: mapmarker,
      iconImageSize: [iconWidth, iconHeight],
      iconImageOffset: [-(iconWidth / 2), -iconHeight],
      hideIconOnBalloonOpen: false
    }); //Обработка клика на маркер

    mark.events.add('click', e => {
      //делаем иконки всех макркеров по умолчанию.
      myMap.geoObjects.each(el => {
        el.options.set({
          iconImageHref: mapmarker
        });
      });
      boxVisible.innerHTML = "";
      const needId = e.get('target').properties.get('blockId'); //Делаем иконку активного маркера красной

      e.get('target').options.set({
        iconImageHref: mapmarkerActive
      });
      const newBox = boxTemplate.content.querySelector(`.js-maploc-${needId}`).cloneNode(true);
      boxVisible.append(newBox);
      setTimeout(() => {
        newBox.classList.add('is-active');
        window.lightbox.reload();
      }, 0);
    });
    myMap.geoObjects.add(mark);
  });
  myMap.setBounds(myMap.geoObjects.getBounds(), {
    checkZoomRange: true,
    zoomMargin: [60, 0, 0, 0]
  });

  if (window.innerWidth > 1100) {} else {
    myMap.behaviors.disable(['rightMouseButtonMagnifier', 'scrollZoom', 'drag']); // let newcoord = myMap.getGlobalPixelCenter();
    // newcoord[1] -= 20;
    // myMap.setGlobalPixelCenter(newcoord);
  }
}
;
export function loadYamaps() {
  const selector = document.querySelector('.js-map-frame');
  if (!selector) return;
  const elem = document.createElement('script');
  elem.src = 'https://api-maps.yandex.ru/2.1/?load=package.map&lang=ru-RU&onload=mapsInit';
  document.getElementsByTagName('body')[0].appendChild(elem);
}