import IMask from 'imask';
/**
 * Маска ввода номера телефона
 * см. https://imask.js.org/
 */

export default (() => {
  document.querySelectorAll('input[type=tel]').forEach(elem => {
    elem.mask = IMask(elem, {
      mask: '+{7} (000) 000-00-00',
      lazy: true,
      autofix: true
    });
  });
  document.addEventListener('focusin', e => {
    const phoneInput = e.target.closest('input[type=tel]');

    if (phoneInput && phoneInput.mask) {
      phoneInput.mask.updateOptions({
        lazy: false
      });
      phoneInput.mask.alignCursorFriendly();
    }
  });
});